import { useEffect } from 'react';
import { useForm, getFormProps } from '@conform-to/react';
import { parseWithZod } from '@conform-to/zod';
import { invariantResponse } from '@epic-web/invariant';
import { cssBundleHref } from '@remix-run/css-bundle';

import {
  json,
  type LoaderFunctionArgs,
  type ActionFunctionArgs,
  type HeadersFunction,
  type LinksFunction,
  type MetaFunction,
} from '@remix-run/node';
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetcher,
  useFetchers,
  useLoaderData,
  isRouteErrorResponse,
} from '@remix-run/react';
import { withSentry } from '@sentry/remix';
// import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { z } from 'zod';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { GlobalProgress } from './components/progress-bar.tsx';
// import { useToast } from './components/toaster.tsx';
import { Icon, href as iconsHref } from './components/ui/icon.tsx';
import { EpicToaster } from './components/ui/sonner.tsx';
import tailwindStyleSheetUrl from './styles/app.css?url';
import fontStylesheetUrl from './styles/font.css?url';
import { ClientHintCheck, getHints, useHints } from './utils/client-hints.tsx';
import { getEnv } from './utils/env.server.ts';
import { honeypot } from './utils/honeypot.server.ts';
import { useNonce } from './utils/nonce-provider.ts';
import { useRequestInfo } from './utils/request-info.ts';
import { type Theme, setTheme, getTheme } from './utils/theme.server.ts';
// import { getToast } from './utils/toast.server.ts';
import { TailwindIndicator } from '#app/components/tw-indicator.tsx';
import { getUserToken } from './utils/auth.server';
import { makeTimings, time } from './utils/timing.server.ts';
import { combineHeaders, getDomainUrl } from './utils/misc.tsx';
import {
  isSystemLive,
  isBasicAuthEnabled,
} from '#app/models/general.server.ts';

export const links: LinksFunction = () => {
  return [
    // Preload svg sprite as a resource to avoid render blocking
    // { rel: 'preload', href: iconsHref, as: 'image' },
    // Preload CSS as a resource to avoid render blocking
    { rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
    {
      rel: 'alternate icon',
      type: 'image/png',
      href: '/favicons/favicon-32x32.png',
    },
    { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
      crossOrigin: 'use-credentials',
    } as const, // necessary to make typescript happy
    //These should match the css preloads above to avoid css as render blocking resource
    { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
    { rel: 'stylesheet', href: fontStylesheetUrl },
    { rel: 'stylesheet', href: tailwindStyleSheetUrl, as: 'style' },
    // {
    //   rel: 'preload',
    //   href: '/img/default-image.jpg',
    //   as: 'image',
    // },
    {
      rel: 'preload',
      type: 'image/svg+xml',
      href: '/img/logos/logo-dfars.svg',
      as: 'image',
    },
    {
      rel: 'preload',
      type: 'image/svg+xml',
      href: '/img/logos/logo-mil-prf.svg',
      as: 'image',
    },
    {
      rel: 'preload',
      type: 'image/svg+xml',
      href: '/img/logos/logo-heico.svg',
      as: 'image',
    },
    cssBundleHref
      ? { rel: 'stylesheet', href: cssBundleHref, disabled: true }
      : null,
  ].filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data, error }) => {
  if (
    (error && process.env.NODE_ENV === 'production') ||
    (isRouteErrorResponse(error) && error.status === 503)
  ) {
    return [{ title: 'VPTPower Maintenance' }];
  }

  return [{ title: data ? 'VPTPower' : 'Error | VPTPower' }];
};

const isAuthorized = (request: { headers: { get: (arg0: string) => any } }) => {
  const header = request.headers.get('Authorization');

  if (!header) return false;

  const base64 = header.replace('Basic ', '');
  const [username, password] = Buffer.from(base64, 'base64')
    .toString()
    .split(':');

  return username === process.env.USERNAME && password === process.env.PASSWORD;
};

export async function loader({ request }: LoaderFunctionArgs) {
  // const { toast, headers: toastHeaders } = await getToast(request);
  const timings = makeTimings('root loader');
  const honeyProps = honeypot.getInputProps();
  const userToken = await getUserToken(request);

  try {
    await time(() => isSystemLive(), {
      timings,
      type: 'getSystemStatus',
      desc: 'getSystemStatus in root',
    });
  } catch (error) {
    console.error('Error checking system status:', error);
    throw new Response('Maintenance Mode', { status: 503 });
  }

  // const basicAuthResponse = await isBasicAuthEnabled();

  const basicAuthResponse = await time(() => isBasicAuthEnabled(), {
    timings,
    type: 'isBasicAuthEnabled',
    desc: 'isBasicAuthEnabled in root',
  });

  const {
    data: {
      global: { enableBasicAuth },
    },
  } = basicAuthResponse;

  if (enableBasicAuth) {
    if (!isAuthorized(request)) {
      throw new Response('Unauthorized', { status: 401 });
    }
  }

  return json(
    {
      authorized: true,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request),
        },
      },
      ENV: getEnv(),
      // toast,
      honeyProps,
      userToken,
    },
    {
      headers: combineHeaders({ 'Server-Timing': timings.toString() }),
    },
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
    'WWW-Authenticate': 'Basic',
  };
  return headers;
};

const ThemeFormSchema = z.object({
  theme: z.enum(['system', 'light', 'dark']),
});

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const submission = parseWithZod(formData, {
    schema: ThemeFormSchema,
  });

  invariantResponse(submission.status === 'success', 'Invalid theme received');

  const { theme } = submission.value;

  const responseInit = {
    headers: { 'set-cookie': setTheme(theme) },
  };
  return json({ result: submission.reply() }, responseInit);
}

function Document({
  children,
  nonce,
  theme = 'light',
  env = {},
  allowIndexing = true,
}: {
  children: React.ReactNode;
  nonce: string;
  theme?: Theme;
  env?: Record<string, string>;
  allowIndexing?: boolean;
}) {
  return (
    <html lang="en" className={`${theme} h-full`}>
      <head>
        <ClientHintCheck nonce={nonce} />
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body className="bg-background text-foreground">
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-N5SGW82Z"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
        {children}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <TailwindIndicator />
      </body>
    </html>
  );
}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const theme = useTheme();
  const allowIndexing = data.ENV.ALLOW_INDEXING !== 'false';
  // useToast(data.toast);
  const userToken = data.userToken;

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    addGtmScript();

    const clickDimensionsScript = document.createElement('script');
    clickDimensionsScript.type = 'text/javascript';
    clickDimensionsScript.src = 'https://analytics.clickdimensions.com/ts.js';

    const clickDimensionsInitScript = document.createElement('script');
    clickDimensionsInitScript.type = 'text/javascript';

    const scriptContent = document.createTextNode(
      `var cdAnalytics = new clickdimensions.Analytics('analytics.clickdimensions.com');
          cdAnalytics.setAccountKey('a3KDhi0X5QkOSAdEelTxQ');
          cdAnalytics.setDomain('vptpower.com');
          cdAnalytics.setScore(typeof(cdScore) == "undefined" ? 0 : (cdScore == 0 ? null : cdScore));
          cdAnalytics.trackPage();`,
    );
    clickDimensionsInitScript.appendChild(scriptContent);

    document.body.appendChild(clickDimensionsScript);
    clickDimensionsScript.onload = () => {
      document.body.appendChild(clickDimensionsInitScript);
    };
  }, []);

  return (
    <Document
      nonce={nonce}
      theme={theme}
      allowIndexing={allowIndexing}
      env={data.ENV}
    >
      <div className="flex h-screen flex-col justify-between">
        {/* <header className="container py-6">
          <nav>
            <Logo />
          </nav>
        </header> */}

        <div className="flex-1">
          <Outlet context={{ userToken }} />
        </div>

        {/* <div className="container flex justify-between pb-5">
          <Logo />
          <ThemeSwitch userPreference={data.requestInfo.userPrefs.theme} /> 
        </div> */}
      </div>
      <EpicToaster closeButton position="top-center" theme={theme} />
      <GlobalProgress />
    </Document>
  );
}

function Logo() {
  return (
    <Link to="/" className="group grid leading-snug">
      <span className="font-light transition group-hover:-translate-x-1">
        epic
      </span>
      <span className="font-bold transition group-hover:translate-x-1">
        notes
      </span>
    </Link>
  );
}

// function AppWithProviders() {
//   const data = useLoaderData<typeof loader>();
//   return (
//     <HoneypotProvider {...data.honeyProps}>
//       <App />
//     </HoneypotProvider>
//   );
// }
export default withSentry(App);

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
  const hints = useHints();
  const requestInfo = useRequestInfo();
  const optimisticMode = useOptimisticThemeMode();
  if (optimisticMode) {
    return optimisticMode === 'system' ? hints.theme : optimisticMode;
  }
  return requestInfo.userPrefs.theme ?? hints.theme;
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
  const fetchers = useFetchers();
  const themeFetcher = fetchers.find((f) => f.formAction === '/');

  if (themeFetcher && themeFetcher.formData) {
    const submission = parseWithZod(themeFetcher.formData, {
      schema: ThemeFormSchema,
    });

    if (submission.status === 'success') {
      return submission.value.theme;
    }
  }
}

function ThemeSwitch({ userPreference }: { userPreference?: Theme | null }) {
  const fetcher = useFetcher<typeof action>();

  const [form] = useForm({
    id: 'theme-switch',
    lastResult: fetcher.data?.result,
  });

  const optimisticMode = useOptimisticThemeMode();
  const mode = optimisticMode ?? userPreference ?? 'system';
  const nextMode =
    mode === 'system' ? 'light' : mode === 'light' ? 'dark' : 'system';
  const modeLabel = {
    light: (
      <Icon name="sun">
        <span className="sr-only">Light</span>
      </Icon>
    ),
    dark: (
      <Icon name="moon">
        <span className="sr-only">Dark</span>
      </Icon>
    ),
    system: (
      <Icon name="laptop">
        <span className="sr-only">System</span>
      </Icon>
    ),
  };

  return (
    <fetcher.Form method="POST" {...getFormProps(form)}>
      <input type="hidden" name="theme" value={nextMode} />
      <div className="flex gap-2">
        <button
          type="submit"
          className="flex h-8 w-8 cursor-pointer items-center justify-center"
        >
          {modeLabel[mode]}
        </button>
      </div>
    </fetcher.Form>
  );
}

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
      <GeneralErrorBoundary />
    </Document>
  );
}

function addGtmScript() {
  (function (w: Window, d: Document, s: 'script', l: string, i: string) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement<'script'>(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode?.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-N5SGW82Z');
}
